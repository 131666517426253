import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { DeleteIcon } from '../../coatings/icons';
import FileUpload from '../../common/FileUpload';
import GenericDialog from '../../common/GenericDialog';
import { useLocation } from 'react-router-dom';
import { UserFeedbackCreate } from '../types';
import { clarity } from '../../../clarity';

type Props = {
  open: boolean;
  onConfirm: (feedback: UserFeedbackCreate) => Promise<void>;
  onCancel: () => void;
  confirmDisabled?: boolean;
  initialFiles: File[];
};

/**
 * Displays a feedback dialog with a subject, body, and file upload input.
 *
 * This component allows users to submit feedback with an optional file upload.
 * @param open
 * @param onConfirm
 * @param onCancel
 * @param confirmDisabled
 * @param initialFiles
 */

const FeedbackDialog = ({
  open,
  onCancel,
  onConfirm,
  confirmDisabled,
  initialFiles,
}: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState(initialFiles);
  const location = useLocation();
  const cantSave =
    confirmDisabled || name.trim() === '' || message.trim() === '';

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  /**
   * Sets selected files in local state
   * @param files
   */
  const handleFileChange = (files: File[]) => {
    setFiles(files);
  };

  /**
   * Handles the confirmation action for the dialog.
   */
  async function handleConfirm() {
    await onConfirm({
      name: name,
      message: message,
      attachments: files,
      sessionId: clarity.getSessionId(),
      pathname: location.pathname,
    });
    setName('');
    setMessage('');
    setFiles([]);
  }

  /**
   * Handles the cancel action for the dialog.
   */
  function handleCancel() {
    onCancel();
    setName('');
    setMessage('');
    setFiles([]);
  }

  return (
    <GenericDialog
      title={t('reportCCCIssue')}
      open={open}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmDisabled={cantSave}
    >
      <Box>
        <TextField
          fullWidth
          label={t('message')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={4}
          sx={{ mb: 2 }}
          variant='standard'
          inputProps={{ maxLength: 2000 }}
          helperText={`${message.length}/2000`}
        />
        <TextField
          fullWidth
          label={t('name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 3 }}
          variant='standard'
        />
        <FileUpload
          initialFiles={files}
          value={files}
          onChange={handleFileChange}
          fileListRenderer={(fs, onDelete) => (
            <div>
              {files.map((f, i) => (
                <Grid
                  key={f.name}
                  container
                  spacing={2}
                  alignItems='center'
                  sx={{ mt: 0.5 }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <Grid item>
                      <Typography>{f.name}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => onDelete(f)} size='large'>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        />
      </Box>
    </GenericDialog>
  );
};

export default FeedbackDialog;
