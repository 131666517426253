export const QueryKeys = {
  LMS_INTEGRATION_CONFIGS: 'lmsIntegrationConfigs',
  LMS_ASSESSMENT_STATUSES: 'lmsAssessmentStatuses',
  MCELL: 'mCell',
  MCELLS: 'mCells',
  MCELL_COMPLETION_CONFIGS: 'mCellCompletionConfigs',
  PRODUCING_LOCATIONS_OPTIONS: 'producingLocations',
  PRODUCTION_LINES_OPTIONS: 'productionLines',
  WORK_CENTERS_OPTIONS: 'workCenters',
  WORK_CENTER_STATIONS_OPTIONS: 'workCenterStations',
  USER_FEEDBACK: 'userFeedback',
};
