import { SvgIcon } from '@mui/material';
import { mdiDelete, mdiPaperclip, mdiPlus } from '@mdi/js';
import React from 'react';

/**
 * AddIcon
 * @param style
 * @param props
 * @constructor
 */
export const AddIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPlus} />
    </SvgIcon>
  );
};

/**
 * AttachFileIcon
 * @param style
 * @param props
 * @constructor
 */
export const AttachFileIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiPaperclip} />
    </SvgIcon>
  );
};

/**
 * DeleteIcon
 * @param style
 * @param props
 * @constructor
 */
export const DeleteIcon = ({ style, ...props }: any) => {
  return (
    <SvgIcon {...props} style={style}>
      <path d={mdiDelete} />
    </SvgIcon>
  );
};
