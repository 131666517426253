import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUserFeedback } from './api';
import { MutateOptions } from '@tanstack/react-query';
import { QueryKeys } from '../../api/queryKeys';
import { UserFeedbackCreate } from './types';

interface UserFeedback {
  subject: string;
  body: string;
  files: File[];
  sessionId: string;
}

/**
 * Hook to submit user feedback
 */
export const useSubmitUserFeedback = (
  options?: MutateOptions<void, Error, UserFeedback>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feedback: UserFeedbackCreate) => createUserFeedback(feedback),
    onSuccess: () => {
      // Invalidate relevant queries if needed
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USER_FEEDBACK],
      });
    },
  });
};
