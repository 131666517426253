import axiosInstance from '../../api';
import { UserFeedback, UserFeedbackCreate } from './types';

/**
 * API call to create user feedback
 * @param feedbackData
 */
export const createUserFeedback = async (
  feedbackData: UserFeedbackCreate,
): Promise<UserFeedback> => {
  const formData = new FormData();
  formData.append('name', feedbackData.name);
  formData.append('message', feedbackData.message);
  formData.append('pathname', feedbackData.pathname);
  formData.append('sessionId', feedbackData.sessionId ?? '');

  if (feedbackData.attachments && feedbackData.attachments.length > 0) {
    feedbackData.attachments.forEach((file) => {
      formData.append('attachments', file);
    });
  }

  return await axiosInstance.post('/api/App/feedback', formData);
};
