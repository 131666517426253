export module clarity {
  /**
   * Initialize Clarity tracking.
   * @param id This is the ID which tells Clarity which site settings it should load and where it should save the data collected.
   */
  export function init(id: string): void {
    (function (c: any, l: Document, a: string, r: string, I: string) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      const t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = 'https://www.clarity.ms/tag/' + I;
      const y = l.getElementsByTagName(r)[0];
      y.parentNode?.insertBefore(t, y);
    })(window, document, 'clarity', 'script', id);
  }

  /**
   * Check if Clarity has been initialized
   */
  export function hasStarted(): boolean {
    // Implementation of hasStarted method (you need to provide this)
    return !!(window as any).clarity;
  }

  /**
   * Identify user
   * @param userId Unique ID of a user
   * @param properties Additional properties describing your user
   */
  export function identify(
    userId: string,
    properties: Record<string, any>,
  ): void {
    (window as any).clarity('identify', userId);
    Object.keys(properties).forEach((key) => {
      (window as any).clarity('set', key, properties[key]);
    });
  }

  /**
   * Cookie Consent
   */
  export function consent(): void {
    (window as any).clarity('consent');
  }

  /**
   * Setup custom tags
   * @param key The tag name that identifies the kind of information you're sending
   * @param value The value attached to the tag
   */
  export function setTag(key: string, value: string): void {
    (window as any).clarity('set', key, value);
  }

  /**
   * Upgrade Session
   * @param reason The reason of the session upgrade
   */
  export function upgrade(reason: string): void {
    (window as any).clarity('upgrade', reason);
  }

  /**
   * Get current Clarity session ID
   * @returns string | undefined Session ID if available
   */
  export function getSessionId(): string | undefined {
    const match = document.cookie.match(/_clck=([^;]+)/);
    if (!match) {
      return undefined;
    }

    // Decode the value so that '%7C' etc. becomes '|'
    const decodedValue = decodeURIComponent(match[1]);
    // e.g., '1yldwb7|2|ft0|0|1638'

    // Now split on '|'
    const parts = decodedValue.split('|');
    // Typically:
    //   parts[0] -> user ID (e.g., '1yldwb7')
    //   parts[1] -> visit count (e.g., '2')
    //   parts[2] -> current session ID (e.g., 'ft0')
    //   parts[3] -> 0 or 1 (active session flag)
    //   parts[4] -> timestamp

    // Return the 3rd entry as the session ID
    return parts[0];
  }
}
